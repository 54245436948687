import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import {
  axiosStripeInstance,
  useAxiosStripeInterceptors,
} from '../api/apiInstances';
import { UserProfile } from '../common/types/userProfile';
import Loader from '../components/shared/layout/Loader';
import useGenericMutation from '../CustomHooks/useMutation';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireTrialVisit?: boolean;
}

const ProfileProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { mutateAsync: createMongoUser } = useGenericMutation<
    any,
    any,
    { email: string; firstName: string; lastName: string; createdAt: Date }
  >('/users/create', 'POST', axiosStripeInstance);

  useAxiosStripeInterceptors();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user?.id) {
        try {
          await axiosStripeInstance.get<UserProfile>(`/users/${user.id}`);
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
          if (user.emailAddresses && user.emailAddresses.length > 0) {
            try {
              await createMongoUser({
                email: user.emailAddresses[0].emailAddress,
                firstName: user.firstName ?? '',
                lastName: user.lastName ?? '',
                createdAt: user.createdAt ?? new Date(),
              });
            } catch (createError) {
              console.error('Failed to create new user:', createError);
            }
          }
          navigate('/profile', { replace: true });
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, [user, navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader stroke="stroke-blue-500" />
      </div>
    );
  }

  return <>{children}</>;
};

const TrialProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useAxiosStripeInterceptors();

  useEffect(() => {
    const checkTrialPageVisit = async () => {
      if (user?.id) {
        try {
          const response = await axiosStripeInstance.get<UserProfile>(`/users/${user.id}`);
          if (!response.data.hasVisitedTrialPage) {
            navigate('/trial', { replace: true });
          }
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
          navigate('/profile', { replace: true });
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    checkTrialPageVisit();
  }, [user, navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader stroke="stroke-blue-500" />
      </div>
    );
  }

  return <>{children}</>;
};

export { ProfileProtectedRoute as default, TrialProtectedRoute };
